export default {
  FireArrow: 'Атковать воина силой 2',
  MeteorShower: 'Отнимает 3 здоровья у всех воинов в шеренге и 3 здоровья у всех воинов в соседней шеренге (приоритет - верхняя шеренга)',
  FireBall: 'Атаковать воина силой 3',
  PowerOfFire: 'Кладется рядом с полем боя. Все ваши воины получают +2 к базовой атаке до конца вашего хода',
  Rage: 'Накладывается на воина, он получает + 1 к атаке',
  ExplosiveTrap: 'РЕАКЦИЯ. Кладется рядом с полем. Воин, который только что переместился или вошел в игру, теряет 2 здоровья, а также все прилегающие к нему воины',
  FireSplash: 'Атаковать воина и прилегающих к нему всех других воинов силой 1',
  FireShield: 'Вражеские воины, при атаке воина с огненным щитом в свой ход, получают физический урон равный силе этой атаки',
  FireBlast: 'Атаковать воина и прилегающих к нему всех других воинов силой 4',
};
