export default {
  LivingWall: 'БОЕЦ. НЕ ДВИГАЕТСЯ',
  MageApprentice: 'СТРЕЛОК. Может атаковать любого воина на поле',
  GremlinShooter: 'СТРЕЛОК. Имеет ответный удар стрелкам силой 1',
  IceElement: 'СТРЕЛОК. Перед смертью, оглушает ближайшего вражеского воина в шеренге где он стоял',
  EarthGolem: 'БОЕЦ. Вместо своего хода, вылечить 1 здоровье у союзных воинов в прилегающих клетках',
  Gargoyle: 'ЛЕТАЮЩИЙ. Когда Стрелки в свой ход атакуют Гаргулью силой атаки более 1, урон от этих атак снижается на 1 единицу',
  FireGolem: 'БОЕЦ. ПОСЛЕДНИЙ ВЫЗОВ: отнимает 1 здоровье у ВСЕХ воинов в шеренге в которой он находился',
  Rakshasa: 'БОЕЦ. Имеет УКЛОНЕНИЕ',
  YoungGenie: 'ЛЕТАЮЩИЙ. Когда входит в игру, может переместить любого воина на поле на соседнюю пустую клетку',
  EnergyElemental: 'СТРЕЛОК. Имеет ПРОНИКАЮЩУЮ АТАКУ 2',
  IceGiant: 'БОЕЦ. При входе в игру оглушает союзных и вражеских воинов в шеренге в которую входит',
  YoungGenieCast: 'Выбрать воина для перемещения',
  Academia: 'Могучие маги повелевающие элементалями',
  Wisdom: 'СПОСОБНОСТЬ ГЕРОЯ. Вытащить целевую карту заклинания из вашего кладбища и сыграть ее бесплатно',
  IntellectPower: 'СПОСОБНОСТЬ ГЕРОЯ. Прибавляет вам 6 очков действия в этом ходу',
  ForgottenSpell: 'СПОСОБНОСТЬ ГЕРОЯ. Отправляет целевое вражеское заклинание наложенное на поле на кладбище',
  PowerChannel: 'Прибавить себе 1 очко действия в этом ходу и отнять 3 очка действия у врага',
  NalaHero: 'Стрела магии: отнять 1 здоровье у воина на поле боя за 2д. Сила: взять карту из колоды за 1д.',
  AstralHero: 'Телепорт: перенести союзного воина на свободную клетку за 3д. Сила: взять карту из колоды за 1д.',
  factionInfo: 'Могучие маги повелевающие элементалями и искусно владеющие заклинаниями контроля',
};
