export default {
  LivingWall: 'FIGHTER. Can\'t move and can\'t attack in his turn',
  MageApprentice: 'SHOOTER. Can attack any warrior on the field',
  GremlinShooter: 'SHOOTER. Can retaliate to shooter with power 1',
  IceElement: 'SHOOTER. Before death, stun random enemy in the row where was standing.',
  EarthGolem: 'FIGHTER. On game entering, heals 1 health from allied warriors in adjacent cells',
  Gargoyle: 'FLYER. When Shooter attacks Gargoyle in his turn with power more then 1 - decrease this attack power by 1',
  FireGolem: 'FIGHTER. Before death, attack all warriors in the row where standing with power 1',
  Rakshasa: 'FIGHTER. Has EVASION',
  YoungGenie: 'FLYER.  On game entering, can move any enemy warrior to the next cell (if possible)',
  EnergyElemental: 'SHOOTER. Имеет ПРОНИКАЮЩУЮ АТАКУ 2',
  IceGiant: 'FIGHTER. On game entering, stun all warriors in the row he entered',
  ArcaneArmor: 'REACTION: Absorbs damage of warrior\'s attack with power 3 or more',
  Blizzard: 'Put on central cell. Attack all warriors in the row with power 2. TURN START: attack all warriors in the row with power 2',
  CursedChackle: 'Attach to an enemy warrior. When this warrior perform attack in his turn, attack enemy hero with the same power as this attack',
  Frostbite: 'Attach to an enemy warrior. His attack power decreased by 2.',
  FrostTrap: 'REACTION: enemy warrior that just entered battlefield or moved returns to his owner\'s hand and attached spells return to thier owners',
  IceBlock: 'Attach to your hero. Protect your hero from any damage during enemy\'s turn',
  IceSpikes: 'Attack all warriors in the selected line with power 2',
  IceWall: 'Put on central cell. Shooters and Fighters in this row can\'t attack during thier turn',
  IceWeapon: 'Attach to warrior. This warrior\'s attack power increased by 2.',
  Icing: 'Put on central cell. Warriors in this row can\'t attack and move during thier turn',
  InvisibilityCloak: 'Attach to warrior. This warrior gets INVISIBILITY and MAGIC SHIELD',
  SoulDrain: 'Kills enemy warrior on the battlefield',
  Teleport: 'Move allied warrior on any free and appropriate cell',
  TownPortal: 'Return enemy warrior on the battlefield to his owner hand and attached spells return to thier owners',
  Transformation: 'Stun enemy warrior and move him to the next cell (if available)',
  Tsunami: 'Attack all non Flyer warriors on the battlefield with power 7 or with power 8 if this is postponed card',
  Waterfall: 'Put close to the battlefield. All warriors on the battlefield with current attack power 2 or less can\'t attack in thier turn',
  IceArrow: 'Attack warrior with power 2 and stun this warrior',
  IceArmor: 'Attach to your hero or ally warrior. During enemy\'s turn decrease all attacks power on this warrior or hero by 1',
  YoungGenieCast: 'Choose warrior to move',
  NalaHero: 'Magic arrow: hit an enemy warrior with power of 1 - 2p. Strength: take a card from your deck into your hand - 1p.',
  factionInfo: 'Magicians who command elementals and are proficient in control spells',
};
