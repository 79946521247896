export default {
  BattleCry: 'НАВЫК. СПОСОБНОСТЬ ГЕРОЯ. Накладывается на вашего героя - повернутый герой становится снова активным',
  MeleeCombat: 'НАВЫК. СПОСОБНОСТЬ ГЕРОЯ. Накалдывается на Бойца или Летающего, этот воин получает +1 к атаке',
  RangeCombat: 'НАВЫК. СПОСОБНОСТЬ ГЕРОЯ. Накладывается на Стрелка, этот воин получают +1 к атаке',
  Protection: 'НАВЫК. СПОСОБНОСТЬ ГЕРОЯ. Накладывается на воина, он получает +1 защиту от атак других воинов в их ход',
  Resistance: 'НАВЫК. СПОСОБНОСТЬ ГЕРОЯ. Накладывается на воина, он получает 50% защиту от ударных заклинаний',
  Durability: 'НАВЫК. СПОСОБНОСТЬ ГЕРОЯ. Накладывается на воина, он получает +3 к здоровью',
  FirstAid: 'НАВЫК. СПОСОБНОСТЬ ГЕРОЯ. Накладывается на воина, он излечивает у себя 2 здоровья в начале вашего хода',
  Leadership: 'НАВЫК. СПОСОБНОСТЬ ГЕРОЯ. Накладывается на воина, он получает СТРЕМИТЕЛЬНОСТЬ',
  BattleTactics: 'НАВЫК. СПОСОБНОСТЬ ГЕРОЯ. Кладется рядом с вашей линией. Все воины в этой линии получают СТРЕМИТЕЛЬНОСТЬ в этом ходу',
};
