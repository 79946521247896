export default {
  LivingWall: 'Живая стена',
  MageApprentice: 'Ученица мага',
  GremlinShooter: 'Гремлин стрелок',
  IceElement: 'Элемент льда',
  EarthGolem: 'Земляной голем',
  Gargoyle: 'Гаргулья',
  FireGolem: 'Огненный голем',
  Rakshasa: 'Ракшаса',
  YoungGenie: 'Молодой джин',
  EnergyElemental: 'Элемент энергии',
  IceGiant: 'Ледяной гигант',
  Wisdom: 'Мудрость',
  IntellectPower: 'Сила интеллекта',
  ForgottenSpell: 'Рассеивание заклинания',
  PowerChannel: 'Канал силы',
  NalaHero: 'Нала-Герой',
  AstralHero: 'Астрал-Герой',
};
