export default {
  ImperialShooter: 'SHOOTER.',
  MasterShooter: 'SHOOTER. Can retaliate with power 1',
  YoungPriestess: 'SHOOTER. TURN START: Heal 1 health of warrior in random adjacent cell',
  WarriorOfLight: 'FIGHTER. Can move and attack in the same turn',
  ValorKnight: 'FIGHTER. On his attack, heal 1 health of warriors in the next cells on this line',
  Bannerman: 'FIGHTER. When entering battlefield can increase attack power of allied warrior by 2 for one attack',
  Defender: 'FIGHTER. Has MAGIC SHIELD. Enemy warriors can\'t attack another warrior in the row where Defender stands',
  RighteousFighter: 'FIGHTER. Get + 1 attack power against hero',
  KnightGuardian: 'FIGHTER. Enemy warriors can\'t attack warriors in the next cells of Knight guardian but can attack Knight guardian instead',
  ImperialGriffon: 'FLYER. Get +1 attack power to retaliation strike against Fighters',
  TemplarChampion: 'FIGHTER. When entering battlefield, can heal 3 health points from allied warrior. When attacking warrior hitting another enemy in the row with power 2',
  MasterOfLight: 'FIGHTER. Instead of his turn can attack with spell damage and power 2 all enemies in the row where standing (get no retaliation)',
  ThunderBlast: 'Attack enemy warrior with spell power 3 or 4 if this is postponed card, additionally stun all warriors in the adjacent cells',
  Swiftness: 'Attach to an allied warrior. This warrior can move and then attackin the same turn',
  Resurrection: 'Take a warrior card from your stock and play it for free. Ressurection can\'t be postponed card',
  Retribution: 'An allied warrior gets +1 to attack power on his turn, and +1 to attack power for every your positive spell on your warriors or on the battlefield',
  AttackAura: 'Attach to an allied warrior. Attacks a random enemy standing in the same row with this warrior with a power of 2 when played and as LAST CAST',
  HealingLight: 'Heal 3 health of a warrior, or 4 health if this card is postponed',
  YouthFountain: 'Put it next to the field. When enemy play warrior card or moves a warrior card on the field - heal 2 health of your hero',
  Enlightenment: 'Placed next to the field, all allied warriors get +1 to attack power on their turn',
  HeavenProtection: 'Attach to an allied warrior. Absorbs the first damage to this warrior from the base attack of other warriors, after that the Heaven Protection is sent to the grave',
  Bless: 'Attach to an allied warrior, this warrior gets + 1 attack power',
  HolyLand: 'Place on a central cell. Attack enemy warriors who moves or enters the game in a the row where there is a Holy land with power 2',
  HeavenShock: 'Attack enemy warrior on the field with power 2 and cure 2 health from your hero',
  SaintWord: 'Attack with power 2 or power 3, if this card is postponed, all enemy warriors in the row, as well as with power 2 enemy warriors in a random neighboring row',
  RighteousHammer: 'Attack warrior with power 3 + X where X is equal to: the number of enemy warriors on field minus the number of your warriors on the field',
  RetaliationHammer: 'Attack warrior with power 2 and move him to the next cell',
  LightShield: 'Attach to an allied warrior. This warrior gets +2 to health',
  LastChance: 'REACTION: When an enemy warrior deals fatal damage to your warrior, your warrior does not die, but remains on the field with 1 health',
  Conciliation: 'Place next to the battlefield. Prevents any damage to your warriors and hero during the enemy\'s turn',
  TemplarChampCast: 'Choose warrior to heal',
  BannermanCast: 'Choose warrior to increase his attack power',
  ZigfridHero: 'Hammer blow: attack a warrior with a power of 1 and move him to the next cell - 4p. Strength: take a card from your deck into your hand - 1p.',
  factionInfo: 'Mighty knights relying on healing and empowering their troops',
  DummyCard: 'You gain additional action point until the end of your turn',
};
