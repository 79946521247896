export default {
  BattleCry: 'Боевой клич',
  MeleeCombat: 'Ближний бой',
  RangeCombat: 'Дальний бой',
  Protection: 'Доспехи',
  Resistance: 'Сопротивление магии',
  Durability: 'Стойкость',
  FirstAid: 'Первая помощь',
  Leadership: 'Лидерство',
  BattleTactics: 'Тактика',
};
